<template>
  <!-- Nav Top Banner Bar -->
  <NavBanner/>
  <Navbar ref="navbar"/>
  <router-view/>
  <Snackbar ref="snackbar"/>
  <Footer/>
</template>

<script>
  import Navbar from '@/components/navigation/Navbar.vue'
  import NavBanner from '@/components/navigation/NavBanner.vue'
  import Footer from '@/components/footer/Footer.vue'
  import Snackbar from '@/components/common/Snackbar.vue'

  export default {
    name: 'App',
    components: { Navbar, NavBanner, Snackbar, Footer}
  }
</script>
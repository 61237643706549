<template>

    <!-- Nav Top Banner Bar -->
    <div class="nav-top-bar-wrapper">
        <div class="nav-top-bar">
            <div class="nav-top-left" title="Country Selector">
                <img class="country-selector-img" src="@/assets/flags/us-flag.png" alt="us flag"/>
                <p class="nav-country-abbr">US</p>
            </div>
            <div class="nav-top-center">
                <p class="nav-top-text">Get free delivery on orders above $50</p>
            </div>
            <div class="nav-top-right">
                <div class="nav-auth-action-box">
                    <a href="/sign-in" title="Sign In"><p class="nav-auth-text-btn nav-auth-text-left">Sign in</p></a>
                    <a href="/sign-up" title="Create Account"><p class="nav-auth-text-btn">Register</p></a>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: 'NavBanner'
}
</script>

<style scoped>
    /* Navbar Top Bar */
    .nav-top-bar-wrapper{width: 100%;height: 35px;background-color: #202938;}
    .nav-top-bar{display: grid;grid-template-columns: 1fr auto 1fr;max-width: 1280px;margin: auto;padding: 0 12px;}
    .country-selector-img{height: 17px;margin: 9px 0;display: inline-block;cursor: pointer;}
    .nav-country-abbr{color: #fff;font-size: 14px;font-weight: 400;display: inline-block;line-height: 17px;cursor: pointer;margin-left: 4px;}
    .nav-top-center{text-align: center;}
    .nav-top-text{color: #f1f1f1;font-size: 14px;line-height: 19px;padding: 8px 0;}
    .nav-auth-action-box{text-align: right;}
    .nav-auth-text-btn{color: #f1f1f1;display: inline-block;font-size: 14px;font-weight: 400;line-height: 23px;margin: 6px 0px;padding: 0 8px;cursor: pointer;}
    .nav-auth-text-left{border-right: 1px solid #727272;}
    .nav-auth-text-btn:hover{text-decoration: underline;}

    /* Tablet */
    @media only screen and (max-width: 1023px){
      .nav-top-bar{display: block;}
      .nav-top-left, .nav-top-right{display: none;}
    }
</style>
<template>
    <div class="mc-dimmer" :style="mainStyle" @click.stop="closeMiniCart()">

        <div class="mc" :style="cartStyle" id="mc" @click.stop>
            <div class="mc-content">
                <div class="mc-header-box">
                    <p class="mc-header">Shopping Cart</p>
                    <svg @click="closeMiniCart()" width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#9ba3af" aria-hidden="true" class="mc-close"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                </div>
                

                <div class="mc-body" v-if="cartItems.length>0">
                    <ul>
                        <li class="mc-item" v-for="item in cartItems" :key="item">
                            <div>
                                <img class="mc-image" :src="'https://firebasestorage.googleapis.com/v0/b/flikbox-2606.appspot.com/o/assets%2Fimages%2Fproducts%2F' + item.id +'%2F1.png?alt=media'" alt="Cart Image">
                            </div>
                            <div class="mc-product-details">
                                <div class="mc-name-price-feat-box">
                                    <div class="mc-name-price-box">
                                        <a :href="'/product/'+item.id"><h3 class="mc-product-name">{{item.name}}</h3></a>
                                        <p class="mc-product-price">${{getItemSubtotal(item.price, item.quantity)}}</p>
                                    </div>
                                    <p class="mc-product-feature">{{item.size}}, {{item.color}}</p>
                                </div>
                                <div class="mc-qty-remove-box">
                                    <div>
                                        <select name="qty" :id="'mc-item-qty'+item.id" class="mc-item-qty" @change="quantityUpdated(item)">
                                            <option v-if="item.quantity==1" value="1" selected>1</option>
                                            <option v-if="item.quantity!=1" value="1">1</option>
                                            <option v-if="item.quantity==2" value="2" selected>2</option>
                                            <option v-if="item.quantity!=2" value="2">2</option>
                                            <option v-if="item.quantity==3" value="3" selected>3</option>
                                            <option v-if="item.quantity!=3" value="3">3</option>
                                            <option v-if="item.quantity==4" value="4" selected>4</option>
                                            <option v-if="item.quantity!=4" value="4">4</option>
                                        </select>
                                    </div>
                                    <p @click="deleteFromCart(item)" class="mc-remove-btn">Remove</p>
                                </div>
                            </div>
                        </li>
                    </ul>

                    <div class="mc-checkout-body">
                        <div class="mc-subtotal-box">
                            <p>Subtotal</p>
                            <p>${{subTotal}}</p>
                        </div>
                        <p class="mc-subtotal-desc">Shipping and taxes calculated at checkout.</p>
                    </div>
                </div>

                <div v-if="cartItems.length>0">
                    <a href="/cart"><div class="btn mc-checkout-btn">View Cart</div></a>
                    <div @click="closeMiniCart()" class="continue-shopping-btn">Continue Shopping →</div>
                </div>

                <div class="cart-empty-wrapper" v-if="cartItems.length==0">
                    <p class="center">Cart Empty</p>
                </div>
          </div>
      </div>

      <div class="dimmer" :style="dimmerStyle"></div>

  </div>
</template>

<script>
export default {
    name: "MiniCart",
    data(){
        return{
            dimmerStyle: {},
            cartStyle: {},
            mainStyle: {},
            cartWidth: 0,
            cartItems: [],
            subTotal: 0.0
        }
    },
    mounted(){
        this.cartStyle = {"right": "-"+ document.getElementById("mc").offsetWidth +"px"};
        this.mainStyle = {"display": "none"};
        this.getCartItems();
    },
    methods:{
        // Get Cart Items from Local Storage
        getCartItems(){
            var cartItems = JSON.parse(localStorage.getItem("cartItems"));
            if(cartItems != null){
                this.cartItems = cartItems;
            }
            
            this.calculatePrice();
        },
        // Get Subtotal of each Item
        getItemSubtotal(price, quantity){
            return price*quantity;
        },
        // Update Quantity of Cart Item
        quantityUpdated(item){
            let found = this.cartItems.some(el => (el.id === item.id) && (el.size === item.size) && (el.color === item.color));
            if (found){
                for(let i=0;i<this.cartItems.length;++i){
                    if((this.cartItems[i]["id"] == item.id) && (this.cartItems[i]["size"] == item.size) && (this.cartItems[i]["color"] == item.color)){
                        this.cartItems[i]["quantity"] = document.getElementById('mc-item-qty'+item.id).value;
                    }
                }
            }
            this.calculatePrice();
            localStorage.setItem("cartItems", JSON.stringify(this.cartItems));
            this.$root.$refs['navbar'].getCartItems();
        },
        // Delete Item from Cart
        deleteFromCart(item){
            for(let i=0;i<this.cartItems.length;++i){
                if((this.cartItems[i]["id"] == item.id) && (this.cartItems[i]["size"] == item.size) && (this.cartItems[i]["color"] == item.color)){
                    this.cartItems.splice(i, 1);
                }
            }
            this.calculatePrice();
            localStorage.setItem("cartItems", JSON.stringify(this.cartItems));
            this.$root.$refs['navbar'].getCartItems();
        },
        /* Calculate Final Price of all Items */
		calculatePrice(){
			this.subTotal = 0.0;
			for(let i=0;i<this.cartItems.length;++i){
				this.subTotal = this.subTotal + (parseFloat(this.cartItems[i]["price"]) * parseInt(this.cartItems[i]["quantity"]))
			}
		},
        openMiniCart(){
            let self = this;

            this.mainStyle = {"display": "flex", "opacity": "1"}
            setTimeout(function(){ self.cartStyle = {"right": "0"} }, 10);
            this.dimmerStyle = {"display": "block"}
        },
        closeMiniCart(){
            let self = this;
            setTimeout(function(){ self.mainStyle = {"opacity": "0", "display": "none"} }, 500);
            this.dimmerStyle = {"display": "none"}
            this.cartStyle = {"right": "-"+ document.getElementById("mc").offsetWidth +"px"}
        }
    }
}
</script>

<style scoped>
    .mc-dimmer{position: fixed;top: -1px;right: 0px;bottom: 0px;left: 0px;display: flex;z-index: 41;margin-top: 0;width: 100%;opacity: 0;}
    .mc{position: absolute;top: 0px;z-index: 20;height: 100vh;flex-direction: row-reverse;transition-property: right;transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);transition-duration: 500ms;background-color: #fff;padding: 24px;max-width: 28vw;width: 100%;min-width: 400px;overflow-y: scroll;}
    .mc::-webkit-scrollbar{display: none;}
    .mc-content{overflow: hidden;justify-content: center;position: relative;}
    .mc-header-box{display: flex;justify-content: space-between;}
    .mc-header{text-align: left;line-height: 1.5;font-size: 18px;font-weight: 500;margin: 0;}
    .mc-close{fill: #9ba3af;cursor: pointer;transition: 0.2s all;}
    .mc-close:hover{stroke: #3a3a3a;}

    .mc-body{position: relative;margin-top: 16px;}
    .mc-body ul{padding-bottom: 24px;overflow-y: scroll;height: calc(100vh - 280px);}
    .mc-body ul::-webkit-scrollbar {display: none;}
    .mc-item{padding: 24px 0px;display: flex;}
    .mc-image{height: 90px;object-fit: cover;object-position: center;border-radius: 6px;}
    .mc-product{display: grid;grid-template-columns: 80px auto;width: 100%;grid-gap: 12px;margin-bottom: 12px;padding-bottom: 12px;border-bottom: 1px solid #e9e9e9;}
    .mc-product-details{flex-direction: column;flex: 1 1 0%;display: flex;margin-left: 16px;}
    .mc-name-price-feat-box{position: relative;}
    .mc-name-price-box{line-height: 24px;display: flex;justify-content: space-between;}
    .mc-product-name{font-size: 16px;font-weight: 500;}
    .mc-product-price{margin-left: 16px;font-size: 14px;line-height: 20px;font-weight: 500;}
    .mc-product-feature{color: #6b7280;font-size: 14px;line-height: 20px;margin-top: 4px;}
    .mc-qty-remove-box{flex: 1 1 0%;display: flex;align-items: flex-end;justify-content: space-between;margin-top: 4px;}
    .mc-item-qty{background-color: #fff;padding: 4px;font-weight: 500;color: #495057;font-size: 14px;border: 1px solid #d1d5db;border-radius: 6px;box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgb(0 0 0 / 5%);user-select: none;outline: none;}
    .mc-remove-btn{color: #6366f1;font-size: 14px;font-weight: 500;cursor: pointer;padding: 6px 0px;}
    .mc-remove-btn:hover{text-decoration: underline;}

    .mc-checkout-body{padding: 24px 0px;border-top: 1px solid #e5e7eb;}
    .mc-subtotal-box{flex: 1 1 0%;display: flex;align-items: flex-end;justify-content: space-between;font-weight: 500;}
    .mc-subtotal-desc{color: #6b7280;font-size: 14px;line-height: 20px;margin-top: 4px;}
    .mc-checkout-btn{margin: 0;width: 100%;font-size: 16px;font-weight: 400;line-height: 20px;padding: 12px;}
    .continue-shopping-btn{width: 100%;cursor: pointer;text-align: center;margin: 12px 0;padding: 12px 0;color: #6366f1;font-size: 14px;font-weight: 500;margin-bottom: 0px;}
    
    .cart-empty-wrapper{position: relative;height: calc(100vh - 100px);}

    .dimmer{flex: 1 1 0%;outline: none;z-index: 10;background-color: rgba(0, 0, 0, 0.70);cursor: pointer;}

    /* Phone */
    @media only screen and (max-width: 600px){
        .mc{max-width: 85%;min-width: 60%;padding: 16px;}
        .mc-body ul{height: calc(100vh - 264px);}
        .mc-item{padding: 16px 0px;}
        .mc-image{height: 70px;}
    }
</style>
<template>
    <!-- Footer -->
    <footer class="footer-wrapper" :style="footerStyle">
        <div class="footer-outer">

            <!-- Footer Main -->
            <div class="footer">
                <div class="footer-logo-box">
                    <img class="footer-logo" src="https://firebasestorage.googleapis.com/v0/b/flikbox-2606.appspot.com/o/assets%2Fimages%2Flogos%2Flogo-320.png?alt=media" alt="FlikBox Logo">
                </div>
                <div class="footer-pages-box-wrapper">
                    <div class="footer-pages-box" v-for="item in footerData" :key="item.title">
                        <p class="footer-header">{{item.title}}</p>
                        <ul class="footer-page-list">
                            <li class="footer-text" v-for="page in item.content" :key="page.name">{{page.name}}</li>
                        </ul>
                    </div>
                </div>
                <div class="footer-subscribe-box-wrapper">
                    <div class="footer-subscribe-box">
                        <p class="footer-header">Sign up to our newsletter</p>
                        <p class="footer-text">Be the first one to know when we release new products</p>
                        <div class="subscribe-input-container">
                            <input class="input-box" type="email" name="email">
                            <p class="btn">Sign Up</p>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Footer Subscribe Card -->
            <div class="footer-subscribe-card">
                <div class="footer-subscribe-box">
                    <p class="footer-header">Sign up to our newsletter</p>
                    <p class="footer-text">Be the first one to know when we release new products</p>
                    <div class="subscribe-input-container">
                        <input class="input-box" type="email" name="email">
                        <p class="btn">Sign Up</p>
                    </div>
                </div>
            </div>

            <!-- Footer Copyright -->
            <div class="footer-bottom-box">
                <p class="copyright-text">© 2021 FlikBox</p>
            </div>

        </div>
    </footer>
</template>

<script>
export default {
    name: 'Footer',
    data(){
        return{
            footerStyle: {},
            footerData: [{"title": "Products", "content": [{"name": "Work Bags", "url": "#"}, {"name": "Leisure Bags", "url": "#"}, {"name": "Travel Bags", "url": "#"}, {"name": "Accessories", "url": "#"}, {"name": "Jacket", "url": "#"}]}, {"title": "Company", "content": [{"name": "Who we are", "url": "#"}, {"name": "Sustainability", "url": "#"}, {"name": "Press", "url": "#"}, {"name": "Career", "url": "#"}, {"name": "Terms & Conditions", "url": "#"}, {"name": "Privacy", "url": "#"}]}, {"title": "Customer Service", "content": [{"name": "Contact", "url": "#"}, {"name": "Shipping", "url": "#"}, {"name": "Returns", "url": "#"}, {"name": "Warranty", "url": "#"}, {"name": "FAQ", "url": "#"}]}]
        }
    },
    mounted(){
        let self = this;
        setTimeout(function(){
			self.footerStyle = {'visibility': 'visible','opacity':'1'}
		},100);
    }
}
</script>

<style scoped>
    .footer-wrapper{width: 100%;background-color: #fff;opacity: 0;visibility: hidden;}
    .footer-outer{max-width: 1280px;margin: auto;padding: 64px 12px;padding-bottom: 0px;}
    
    .footer{display: grid;grid-template-columns: 50px 3fr 2fr;grid-gap: 48px;border-bottom: 1px solid #f1f1f1;padding-bottom: 48px;}
    .footer-logo{height: 36px;}
    .footer-pages-box-wrapper{display: grid;grid-template-columns: 1fr 1fr 1fr;}
    .footer-header{padding: 8px 4px;padding-bottom: 0;font-size: 14px;font-weight: 500;}
    .footer-page-list{padding: 16px 0 8px 0;}
    .footer-text{padding: 6px 4px;font-size: 14px;font-weight: 400;color: #868686;}
    .footer-subscribe-box-wrapper{margin: 0 24px;margin-right: 0;}
    .footer-subscribe-card{display: none;}

    .subscribe-input-container{display: flex;margin-top: 8px;}

    .copyright-text{font-size: 14px;font-weight: 400;color: #868686;text-align: center;padding: 24px 0;}

    @media only screen and (max-width: 1024px){
      .footer{grid-template-columns: 50px 3fr;border-bottom: none;padding-bottom: 0px;}
      .footer-subscribe-box-wrapper{display: none;}
      .footer-subscribe-card{display: block;border-bottom: 1px solid #f1f1f1;padding-bottom: 24px;}
      .footer-subscribe-box{max-width: 600px;padding: 36px 72px;margin: 24px auto;background-color: #f3f4f6;border-radius: 12px;}
    }

    @media only screen and (max-width: 600px){
      .footer{display: block;}
      .footer-pages-box-wrapper{grid-template-columns: 1fr 1fr;margin-top: 24px;}
      .footer-pages-box {margin-top: 12px;}
      .footer-subscribe-box{padding: 0;background-color: unset;}
    }
</style>
<template>
  <div class="hero-container">
    <img class="hero-image" src="https://s7d2.scene7.com/is/image/aeo/20210730-aehp-20-off-lg?scl=1&qlt=60&fmt=jpeg" alt="Hero Image">
    <!--<img class="hero-image" src="https://s9.gifyu.com/images/bg-image-copy-min.png" alt="Hero Image">-->
    <div class="center hero-detail-box">
      <p class="hero-caption">New Arrivals</p>
      <h1 class="hero-title">New Arrivals: 20% Off</h1>
      <p class="hero-description">Explore the new trending collection in mens and womens fashion</p>
      <div class="hero-buttons-box">
        <a href="#top-selling"><p class="btn hero-button">Start Shopping</p></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'HeroCover'
}
</script>

<style scoped>
    .hero-container{position: relative;background-color: #000;}
    .hero-image{width: 100%;height: calc(75vh - 105px);object-fit: cover;object-position: center top;opacity: 0.8;}
    .hero-detail-box{width: 100%;}
    .hero-caption{color: #fff;text-align: center;font-weight: 500;text-transform: uppercase;}
    .hero-title{color: #fff;font-size: 48px;font-weight: 700;text-align: center;}
    .hero-description{color: #fff;text-align: center;}
    .hero-buttons-box{text-align: center;margin-top: 18px;}
    .hero-button{margin: 0px;padding: 8px 16px;border-radius: 6px;min-width: 160px;background-color: #f4d34b;color: #202938;border: 1px solid #f4d34b;background-color: #ffffff;color: #6366f1;border: 1px solid #ffffff;text-transform: uppercase;}
    .hero-button:hover{background-color: #f1f1f1 !important;}


    @media only screen and (max-width: 1024px){
      .hero-image{max-height: 500px;height: calc(100vh - 105px);min-height: 300px;}
    }

    @media only screen and (max-width: 600px){
      .hero-image{height: 550px;max-height: calc(100vh - 105px);}
      .hero-detail-box{width: 98%;}
      .hero-title{font-size: 32px;margin: 8px 0;}
      .hero-description{line-height: 20px;}
      .hero-button{max-width: 250px;margin: 0;text-transform: uppercase;padding: 12px 24px;font-size: 16px;margin-bottom: 10px;font-weight: 600;}
    }
</style>
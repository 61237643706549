import { createRouter, createWebHistory } from 'vue-router'
import Homepage from '../views/homepage/Homepage.vue'

const routes = [
  {
    path: '/',
    name: 'Homepage',
    component: Homepage,
    meta: {
      title: 'FlikBox | Online Fashion Store',
      metaTags: [
        {
          name: 'description',
          content: 'Online Fashion Store for Trendy Men, Women & Kids'
        },
        {
          property: 'og:description',
          content: 'Online Fashion Store for Trendy Men, Women & Kids'
        }
      ]
    }
  },
  {
    path: '/product/:id',
    name: 'Product',
    component: () => import('../views/product/Product.vue'),
    meta: {
      title: 'FlikBox | Online Fashion Store'
    }
  },
  {
    path: '/collection/:id',
    name: 'Collection',
    component: () => import('../views/collection/Collection.vue'),
    meta: {
      title: 'FlikBox | Online Fashion Store'
    }
  },
  {
    path: '/cart',
    name: 'Cart',
    component: () => import('../views/cart/Cart.vue'),
    meta: {
      title: 'Shopping Cart | FlikBox'
    }
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: () => import('../views/checkout/Checkout.vue'),
    meta: {
      title: 'Checkout | FlikBox'
    }
  },
  {
    path: '/sign-in',
    name: 'SignIn',
    component: () => import('../views/authentication/SignIn.vue'),
    meta: {
      title: 'Sign In | FlikBox'
    }
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    component: () => import('../views/authentication/SignUp.vue'),
    meta: {
      title: 'Sign Up | FlikBox'
    }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('../views/authentication/ForgotPassword.vue'),
    meta: {
      title: 'Forgot Password | FlikBox'
    }
  },
  {
    path: '/auth-action',
    name: 'AuthAction',
    component: () => import('../views/authentication/AuthAction.vue'),
    meta: {
      title: 'FlikBox | Online Fashion Store'
    }
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import('../views/account/Account.vue'),
    meta: {
      title: 'My Account | FlikBox'
    }
  },
  {
    path: '/order-success',
    name: 'OrderSuccess',
    component: () => import('../views/order/OrderSuccess.vue'),
    meta: {
      title: 'Order Success | FlikBox'
    }
  },
  {
    path: '/order-failed',
    name: 'OrderFailed',
    component: () => import('../views/order/OrderFailed.vue'),
    meta: {
      title: 'Order Failed | FlikBox'
    }
  },
  { 
    path: '/:pathMatch(.*)*', 
    component: () => import('../views/misc/PageNotFound.vue')
  }
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  next();
});

export default router

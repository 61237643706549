<template>
    <div class="snackbar-box" :style="snackbarStyle">
        <p style="color: #fff;margin: 0;">{{message}}</p>
    </div>
</template>

<script>
export default {
    name: "Snackbar",
    data(){
        return{
            snackbarStyle: {},
            message: "",
            timezone: ""
        }
    },
    methods:{
        // Show snackbar message
        showToast(message, color){
            let self = this;
            this.message = message;
            this.snackbarStyle = {'visibility': 'visible', '-webkit-animation': 'fadein 0.5s, fadeout 0.5s 6s', 'animation': 'fadein 0.5s, fadeout 0.5s 6s', 'background-color': color}
            setTimeout(function(){
                self.snackbarStyle = {}
            }, 3000);
		},
    }
}
</script>

<style>
    .snackbar-box{min-width: 280px;width: 100%;text-align: center;color: #fff;padding: 12px;position: fixed;z-index: 2147483647;margin: auto;bottom: 0;font-size: 15px;left: 50%;transform: translate(-50%, 0);visibility: hidden;}
    .show-snackbar{visibility: visible;-webkit-animation: fadein 0.5s, fadeout 0.5s 1.0s;animation: fadein 0.5s, fadeout 0.5s 1.0s;}
    @-webkit-keyframes fadein {from {bottom: -10px; opacity: 0;} to {bottom: 0px; opacity: 1;}}
    @keyframes fadein {from {bottom: -10px; opacity: 0;}to {bottom: 0px; opacity: 1;}}
    @-webkit-keyframes fadeout {from {bottom: 0px; opacity: 1;} to {bottom: -10px; opacity: 0;}}
    @keyframes fadeout {from {bottom: 0px; opacity: 1;}to {bottom: -10px; opacity: 0;}}
</style>
<template>
    
    <!-- Product Grid -->
    <div class="product-grid-box-wrapper">
        <!--<div class="product-grid-header-box">
          <p class="product-grid-name">{{productGridData.title}}</p>
          <a :href="productGridData.url" v-if="productGridData.url!=undefined&&productGridData.url.length>1"><p class="product-grid-action">View all ➜</p></a>
        </div>-->
        <div :class="mainGridCount==5 ? 'product-grid-box grid-5' : 'product-grid-box'" v-if="productGridData!=undefined">
          <div class="product-box" v-for="item in productGridData" :key="item.name">
            <a :href="'/product/'+item.id">

                <div class="image-grid-imageContainer" @click="showImage(i)">
                    <div class="image-grid-image" :style="getStyle(item['id'])"></div>
                    <div class="image-grid-loader" :style="loaderStyle"></div>
                </div>

                <!--<div class="product-thumb-box">
                    <img class="product-thumb" :src="'https://firebasestorage.googleapis.com/v0/b/flikbox-2606.appspot.com/o/assets%2Fimages%2Fproducts%2F' + item['id'] + '%2F' + item['id'] + '.jpg?alt=media'">
                </div>-->
                <p class="product-brand">{{item.caption}}</p>
                <p class="product-name">{{item.name}}</p>
                <p class="product-price">${{item.price}}</p>
            </a>
          </div>
        </div>

        <div class="product-grid-box" v-if="productGridData==undefined">
            <div class="loading-box" v-for="i in tempCount" :key="i">
                <div class="image-grid-imageContainer">
                    <div class="image-grid-loader"></div>
                </div>
                    
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: 'ProductGrid',
    data(){
        return{
            tempCount: 8,
            loaderStyle: {}
        }
    },
    props:{
        productGridData:{},
        mainGridCount: {}
    },
    mounted(){
        let self = this;
        setTimeout(function(){
            self.loaderStyle = {"display": "none"}
        }, 5000);
    },
    methods:{
        // Set Product Image
        getStyle(val){
            return {"background": "url('https://firebasestorage.googleapis.com/v0/b/flikbox-2606.appspot.com/o/assets%2Fimages%2Fproducts%2F" + val + "%2F1.png?alt=media')", "background-position": "center"};
        },
    }
}
</script>

<style scoped>
    .product-grid-box-wrapper{background-color: #fff;padding-bottom: 96px;}
    
    .product-grid-action{margin: 0px;line-height: 24px;font-size: 14px;font-weight: 500;color: #3e50b5;user-select: none;padding: 4px 0;float: right;cursor: pointer;}
   
    .loading-box{cursor: pointer;border-radius: 8px;overflow: hidden;}
    .image-grid-image {position: absolute;top: 0;left: 0;z-index: 2;width: 100%;height: 100%;background-repeat: no-repeat;background-position: 0 0;background-origin: border-box;background-size: cover !important;-webkit-transition: -webkit-transform .4s;transition: -webkit-transform .4s;transition: transform .4s;transition: transform .4s,-webkit-transform .4s;will-change: transform;}
    .image-grid-image:hover{-webkit-transform:scale(1.04);transform:scale(1.04)}
    .image-grid-imageContainer {height: 0;padding-top: 133.33333333333331%;overflow: hidden;position: relative;border: 1px solid #f5f5f6;border-radius: 6px;overflow: hidden;}
    .image-grid-loader{position:absolute;z-index:1;top:0;left:0;width:99.99%;height:99.99%;background:#eaeaec linear-gradient(to right,#eaeaec,#dad9d9 35%,#eaeaec 70%,#eaeaec) no-repeat;background:#eaeaec -webkit-gradient(linear,left top,right top,color-stop(0,#eaeaec),color-stop(35%,#dad9d9),color-stop(70%,#eaeaec),to(#eaeaec)) no-repeat;background-size:800px 100%;-webkit-animation:image-grid-loading-skeleton 1.7s cubic-bezier(.25,.46,.45,.94) infinite forwards;animation:image-grid-loading-skeleton 1.7s cubic-bezier(.25,.46,.45,.94) infinite forwards}@-webkit-keyframes image-grid-loading-skeleton{0%{background-position:-468px 0}to{background-position:468px 0}}@keyframes image-grid-loading-skeleton{0%{background-position:-468px 0}to{background-position:468px 0}}
    

    .product-grid-box{display: grid;grid-template-columns: 1fr 1fr 1fr 1fr;grid-gap: 16px;max-width: 1280px;margin: auto;padding: 0px 12px;}
    .grid-5{grid-template-columns: 1fr 1fr 1fr 1fr 1fr;}
    .product-box{margin-bottom: 24px;cursor: pointer;}
    .product-brand{font-size: 12px;font-weight: 400;color: #868686;padding-top: 12px;}
    .product-name{font-size: 15px;font-weight: 500;padding: 4px 0;}
    .product-price{font-size: 14px;font-weight: 600;}

    /* Tablet */
    @media only screen and (max-width: 1024px){
        .product-grid-box{grid-template-columns: 1fr 1fr 1fr;}
        .grid-5{grid-template-columns: 1fr 1fr 1fr 1fr;}
        .product-grid-name{font-size: 22px;}
        .product-grid-action{font-size: 16px;}
        .product-brand{font-size: 12px;}
        .product-name{font-size: 16px;}
        .product-price{font-size: 15px;}
    }

    /* Phone */
    @media only screen and (max-width: 600px){
      .product-grid-box{grid-template-columns: 1fr 1fr;}
      .product-grid-box-wrapper{padding-bottom: 36px;}
    }
</style>
<template>
    
    <!-- Sidebar -->
    <div class="sidebar-dimmer" :style="mainStyle" @click.stop="closeSideBar()">
        <div class="sidebar" :style="cartStyle" id="sidebar" @click.stop>
            <div class="sidebar-content">
                <svg @click="closeSideBar()" xmlns="http://www.w3.org/2000/svg" height="28px" viewBox="0 0 24 24" width="28px" class="sidebar-close"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"></path></svg>
                <div class="side-bar-list-wrapper">
                    <p class="side-nav-menu-item" v-for="item in sideBarData" :key="item">{{item}}</p>
                </div>
            </div>
        </div>

        <div class="dimmer" :style="dimmerStyle"></div>
    </div>

</template>

<script>
export default {
    name: 'SideBar',
    data(){
        return{
            dimmerStyle: {},
            cartStyle: {},
            mainStyle: {},
            cartWidth: 0
        }
    },
    props:{
        sideBarData: {}
    },
    mounted(){
        this.cartStyle = {"left": "-"+ document.getElementById("sidebar").offsetWidth +"px"};
        this.mainStyle = {"display": "none"}
    },
    methods:{
        openSideBar(){
            let self = this;

            this.mainStyle = {"display": "flex", "opacity": "1"}
            setTimeout(function(){ self.cartStyle = {"left": "0"} }, 10);
            this.dimmerStyle = {"display": "block"}
        },
        closeSideBar(){
            let self = this;
            setTimeout(function(){ self.mainStyle = {"opacity": "0", "display": "none"} }, 500);
            this.dimmerStyle = {"display": "none"}
            this.cartStyle = {"left": "-"+ document.getElementById("sidebar").offsetWidth +"px"}
        }
    }
}
</script>

<style scoped>
    .sidebar-dimmer{position: fixed;top: -1px;right: 0px;bottom: 0px;left: 0px;display: flex;z-index: 41;margin-top: 0;width: 100%;opacity: 0;}
    .sidebar{position: absolute;display: flex;top: 0px;z-index: 20;height: 100vh;flex-direction: row-reverse;transition-property: left;transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);transition-duration: 500ms;background-color: #fff;padding: 0 16px;}
    .sidebar-content{width: 280px;overflow: hidden;justify-content: center;position: relative;overflow-y: scroll;padding-bottom: 12px;;}
    .sidebar-content::-webkit-scrollbar {display: none;}
    .sidebar-close{position: absolute;left: 0px;top: 13px;fill: #b9b9b9;cursor: pointer;}
    .dimmer{flex: 1 1 0%;outline: none;z-index: 10;background-color: rgba(0, 0, 0, 0.40);cursor: pointer;}

    .side-bar-list-wrapper{margin-top: 48px;}
    .side-nav-menu-item{padding: 14px 6px;font-weight: 400;letter-spacing: .5px;font-size: 17px;color: #363636;margin: 0;text-transform: capitalize;}
</style>
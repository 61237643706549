import firebase from 'firebase'
import axios from 'axios'


// Firebase Configuration
const config = {
    apiKey: "AIzaSyDSIE2JDPz8WRGSP7ehqsIc5QMFh5JDr3Y",
    authDomain: "flikbox-2606.firebaseapp.com",
    projectId: "flikbox-2606",
    storageBucket: "flikbox-2606.appspot.com",
    messagingSenderId: "195515976423",
    appId: "1:195515976423:web:81a3bb84911f2d33183042",
    measurementId: "G-GG83DKG7QK"
};

if (!firebase.apps.length) {
    firebase.initializeApp(config);
}

// Defining Firebase storage
const storageRef = firebase.storage().ref();

// Function to get Collection File Url
export const getCollectionFileUrl = async (loc) => {
    var urlVal = '';
    await storageRef.child(loc).getDownloadURL().then((url) => {
        urlVal = url;
    });
    return urlVal;
}

// Function to Download Collection File to Web Browser
export const getCollectionFile = async (url) => {
    let collectionData = {};

    await axios.get(url)
    .then((response) => {
        collectionData = response.data;
    })
    .catch((error) => {
        return error
    });
    
    return collectionData;
}
